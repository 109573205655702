import React from "react";
import Layout from "../components/Layout";
import { Helmet } from "react-helmet";
import ContactBlock from "../components/ContactBlock";
import map from "../../static/kaart.png";

const ContactPage = ({ location, data }) => {
  const url = location.pathname ? location.pathname : "";

  return (
    <div className="main">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Contact – Kine-Osteo Hagers</title>
        <meta name="description" content="Aarzel niet om ons te contacteren." />
      </Helmet>
      <Layout routeUrl={url}>
        <div className="container">
          <h1 className="page-title">Contact</h1>
        </div>
        <ContactBlock
          image={map}
          title="Contactgegevens"
          desc={
            <div class="team-member__desc">
              <a href="tel:003250418284">050/41 82 84</a>
              <br />
              <a href="mailto:kine.osteo.hagers@gmail.com">
                kine.osteo.hagers@gmail.com
              </a>
              <br />
              <a href="https://goo.gl/maps/t93mYPBLcTbcVH3r8">
                Lissewegestraat 7, 8370 Uitkerke (Blankenberge)
              </a>
              <br />
              <br />
              <p>
                Er is ruime parkeergelegenheid voor de praktijk.
                <br />
                <strong>
                  Let wel: dit is een BLAUWE ZONE (vergeet uw parkeerschijf
                  niet!)
                </strong>
              </p>
              <p>
                Betalingswijzen: Payconiq (bij voorkeur), cash & per
                overschrijving{" "}
              </p>
            </div>
          }
        ></ContactBlock>
      </Layout>
    </div>
  );
};

export default ContactPage;
