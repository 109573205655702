import React from "react";

const ContactBlock = (props) => {
  return (
    <section className={`section${props.altClass ? ` ` + props.altClass : ""}`}>
      <div className="contact">
        <div className="container">
          <div className="grid grid--align-center">
            <div className="grid__item medium--one-half">
              <a
                href="https://goo.gl/maps/BCwUDfSvTg4EZZPp7"
                className="image-link"
              >
                <div className="visual image-round">
                  <img src={props.image} alt="" />
                </div>
              </a>
            </div>
            <div className="grid__item medium--one-half">
              <h2 className="team-member__title">{props.title}</h2>
              <p className="team-member__info">{props.info}</p>
              {props.desc}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactBlock;
